<template>
  <div v-show="store.ready" class="w-screen h-screen">
    <ToastController />
    <div
      v-show="!store.mobileMenuOpen"
      class="fixed inset-x-0 top-0 flex z-50 items-center justify-between bg-neutral-white p-4 border-b border-neutral-300 md:hidden"
    >
      <PulseCircleLogo class="h-8 w-8" />
      <button class="text-neutral-500">
        <PulseLineBars class="h-6 w-6 cursor-pointer fill-current" @click="store.toggleMobileMenu" />
      </button>
    </div>
    <div v-show="!loading" class="dashboard-layout">
      <div
        class="hidden absolute left-0 z-50 top-0 bottom-0 md:grid grid-cols-2 overflow-hidden"
        style="grid-template-columns: 250px 1fr"
      >
        <div class="border-r border-neutral-300 border-solid w-[250px] z-50 overflow-auto">
          <SideNav style="flex: none; overflow: auto" @store-select:click="storeSelectOpen = !storeSelectOpen" />
        </div>
        <StoreSelect :open="storeSelectOpen" @close="storeSelectOpen = false" />
      </div>
      <div />
      <div class="p-4 lg:p-8 container mx-auto overflow-scroll h-screen">
        <h2 class="text-[28px] font-faktum text-typography-primary">
          <div class="mb-8 flex items-center gap-2 font-semibold justify-between">
            <slot name="icon" />
            <slot name="actions">
              <div id="dashboard-many-actions"></div>
            </slot>
          </div>
        </h2>
        <slot />
      </div>
    </div>
    <div v-show="loading" class="w-screen h-screen flex items-center justify-center">
      <PulseCircleLogo class="h-8 w-8" />
    </div>

    <div v-if="store.mobileMenuOpen" class="fixed right-0 top-0 bottom-0 left-0 overflow-auto z-[100]">
      <div class="fixed inset-0 bg-black opacity-70" />
      <SideNav
        class="bg-neutral-white fixed w-[276px] right-0"
        @menu:click="store.closeMenu"
        @store-select:click="storeSelectOpen = !storeSelectOpen"
      >
        <template #close>
          <div class="p-5 -mt-8">
            <PulseIconButton icon="IconClose" variant="secondary" size="large" @click="store.toggleMobileMenu" />
          </div>
        </template>
      </SideNav>
      <StoreSelect class="z-50" :open="storeSelectOpen" @close="storeSelectOpen = false" />
    </div>
  </div>

  <SearchModal />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApplicationStore } from '~/store/application';
import { useMerchantStore } from '~/store/merchant';
import { useUserStore } from '~/store/user';

const loading = computed(() => {
  return useUserStore().loading || useMerchantStore().loading;
});

const store = useApplicationStore();
const storeSelectOpen = ref(false);
const merchantStore = useMerchantStore();
const { stores } = storeToRefs(merchantStore);

provide('collapsed', false);

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - OrderProtection` : 'Welcome - OrderProtection';
  },
});
</script>

<style lang="scss">
.dashboard-layout {
  @screen md {
    grid-template-columns: 250px 1fr;
    grid-template-rows: 1fr;
    @apply mt-0;
  }

  @screen lg {
    @apply h-screen overflow-hidden;
  }
  @apply mt-20 grid overflow-hidden; // this will need to change
}

.fill-current {
  svg {
    fill: currentColor;
  }
}
</style>
